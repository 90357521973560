<template>
	<div class="select-container">
		<div class="select-input d-space-row">
			<div style="width: 100%;" class="d-space-row p-left-right-10" @click="onClick">
				<!-- <div class="current-icon">
					<img :src="icon" alt="">
				</div> -->
				<input :style="inputStyle" type="text" :disabled="true" v-model="options[selectIndex][key_value]"
					placeholder="" />
					
				<template v-if="iconColor === 'black'">
					<img v-if="is_show" src="../../assets/image/select-up.png" class="small-icon">
					<img v-else src="../../assets/image/select-down.png" class="small-icon">
				</template>
			</div>
			<div v-if="is_show" class="select-value" :style="selectStyle">
				<div class="select-content">
					<div class="options-item d-center-row justify-start" v-for="(option,index) in options" :key="index"
						@click="change(option,index)">
						<!-- <img class="the-icon" :src="option.icon" mode=""></img> -->
						<span :class="selectIndex==index?'active':''">{{option[key_value]}}</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			selectStyle: {
				type: Object
			},
			icon: {
				type: String,
				default: ''
			},
			options: {
				type: Array
			},
			selectIndex: {
				type: [String, Number],
				default: ''
			},
			key_value: {
				type: [String, Number],
				default: ''
			},
			is_show: {
				type: Boolean,
				default: false
			},
			inputStyle: {
				type: Object
			},
			iconColor: {
				type: String,
				default: 'black'
			}
		},
		data() {
			return {
				// is_show: false
			}
		},
		computed: {

		},
		methods: {
			onClick() {
				// this.is_show = !this.is_show
				this.$emit('update:is_show', !this.is_show)
			},
			change(option, index) {
				let {
					icon,
				} = option
				this.$emit('update:icon', icon)
				this.$emit('update:selectIndex', index)
				this.$emit('update:is_show', !this.is_show)
			}
		},
		onLoad() {

		}
	}
</script>

<style lang="scss">
	.select-container {
		height: 100px;
		width: 100%;
		// height: 100%;
		position: relative;
		margin-top: 15px;

		.select-input {
			width: 100%;
			height: 100%;
			position: relative;
			box-sizing: border-box;
			padding-left: 10px;
			padding-right: 30px;
			background: #e9e9e9;
			padding: 10px 16px;
			border-radius: 15px;
			.current-icon {
				img {
					width: 60px;
					height: 60px;
				}
			}

			.the-icon {
				width: 60px;
				height: 60px;
				margin-right: 20px;
			}

			.small-icon {
				width: 30px;
				height: 16px;
			}

			input {
				
				width: 100%;
				height: 100%;
				box-sizing: border-box;
				text-align: left;
				background: transparent;
				font-size: 34px;
				margin: 0;
				padding: 0;
				border: none;
				margin-right: 10px;
				padding-left: 10px;
				color: #000;
			}

			.select-value {
				position: absolute;
				top: 60px;
				left: 0;
				width: 100%;
				z-index: 9999;
				border-radius: 15px;
				font-size: 24px;

				.select-content {
					width: 100%;
					height: 100%;
					overflow-y: scroll;
					box-sizing: border-box;
					padding: 10px 0;
					background: #e9e9e9;
					box-shadow: 0  0 6px 0 rgba(0, 0, 0, 0.3);
					border-radius: 10px;
				}

				.options-item {
					box-sizing: border-box;
					padding: 10px 0;
					padding-left: 20px;
					font-size: 34px;
					color: #000;
					.active {
						// color: #FFFFFF;
					}
				}
			}
		}

	}
</style>