import request from '@/utils/request'

//首页数据
export const  info  = () => request.get('invest/info')

export const  myList  = () => request.get('invest/myList')

export const  add  = (data) => request.post('invest/add',data)

export const  remove  = (data) => request.get('invest/remove',data)

export const  config  = () => request.get('invest/config')


