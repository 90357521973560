<template>
	<div class="my-container p-all-20">
		<div class="top-box d-space-column">
			<!-- 头部背景 -->
			<div class="top-border-bg max-bg"></div>
			<!-- 侧边 -->
			<div class="side-border p-all-10">
			</div>
			<div class="pledge-box max-bg p-all m-bottom">
				<div class="title-box d-center-row">
					<img src="../../assets/image/title-icon-1.png" alt="">
					<span class="m-left-right f-w">{{ i18n.pledge }}</span>
					<img src="../../assets/image/title-icon-2.png" alt="">
				</div>
				<!-- <div class="days d-center-row">
					<span>{{ config.days }}</span>
					<span class="f-30">{{ i18n.day }}</span>
				</div> -->
				<div class="day-box d-space-row">
					<div class="day-item d-center-column max-bg" :class="[current == index ? 'active' : '']"
						v-for="(item, index) in dayList" :key="index" @click="changeDay(index, item.value)">
						<span class="f-34 f-w">{{ item.value }}{{ i18n.day }}</span>
						<span class="f-24">{{ (item.fee) }}%</span>
					</div>
				</div>
				<div class="the-balance d-space-row m-top">
					<span class="f-22">{{ i18n.payType }}</span>
				</div>
				<div class="input-item">
					<div class="select-box">
						<coinType :selectStyle="selectStyle" :selectIndex.sync="selectIndex" :options="options"
							key_value="label" :is_show.sync="is_show">
						</coinType>
					</div>
				</div>
				<div class="the-balance d-space-row m-top">
					<span class="f-22">{{ i18n.balance }}</span>
					<span class="f-24" v-if="selectIndex == 0">{{ parseFloat(wallet.arc).toFixed(4) }}</span>
					<span class="f-24" v-if="selectIndex == 1">{{ parseFloat(arcBalance).toFixed(4) }}</span>
				</div>
				<div class="the-input m-top-20">
					<van-field>
						<template slot="extra">
							<div class="d-space-row">
								<img class="the-icon" src="../../assets/image/lele-icon.png" />
								<span class="f-32">LELE</span>
							</div>
						</template>
						<template slot="input">
							<input type="number" :placeholder="i18n.placeHolder" v-model="amount" />
						</template>
					</van-field>
				</div>
				<div class="buy-btn max-bg d-center-row" @click="nowGet()">
					<span class="f-30">{{ i18n.pledgeNow }}</span>
				</div>
			</div>
			<div class="bottom-border max-bg"></div>
		</div>

		<div class="detail-box" v-if="list.length">
			<div class="d-space-row align-start m-top-bottom">
				<span>{{ i18n.myPledge }}</span>
			</div>
			<template v-for="(item, index) in list">
				<div class="d-item d-space-column">
					<div class="time d-space-row p-left-right">
						<span>{{ item.createTime }}</span>
						<!-- <span>剩余天数：</span> -->
					</div>
					<div class="d-space-row p-all-20">
						<div class="info d-space-column m-right">
							<div class="label">
								<span>{{ i18n.pledgeAmount }}</span>
								<span>{{ i18n.days }}</span>
								<span>{{ i18n.daily }}</span>
							</div>
							<div class="value">
								<span>{{ item.amount }}</span>
								<span>{{ item.days }}</span>
								<span v-if="item.days == 90">{{ dayList[0].fee }}%</span>
								<span v-if="item.days == 60">{{ dayList[1].fee }}%</span>
								<span v-if="item.days == 30">{{ dayList[2].fee }}%</span>
							</div>
						</div>
						<div class="operate-box d-center-row">
							<div class="operate max-bg d-center-row" v-if="item.investType == 2 && item.logStatus == 2"
								@click="refund(item.id)">
								<span>{{ i18n.refund }}</span>
							</div>
						</div>
					</div>
				</div>
			</template>
		</div>
		<div class="empty-tips d-center-row m-top">
			<span>—— {{ i18n.none }} ——</span>
		</div>
	</div>
</template>

<script>
import {
	balance,
	accountInfo
} from "@/api/account.js";

import {
	info,
	myList,
	add,
	remove,
	config
} from "@/api/invest.js";
import coinType from '../components/coinType.vue'

export default {
	components: {
		coinType
	},
	data() {
		return {
			arcBalance: 0,
			userInfo: {},
			amount: '',
			list: [],
			logs: [],
			wallet: {
				arc: 0
			},
			config: {},
			wallet: {
				arc: 0,
				arb: 0
			},
			selectStyle: {
				width: '100%',
				// 3个130,2个90
				height: '90px',
				top: '40px',
				left: '0px'
			},
			is_show: false,
			selectIndex: 0,

			options: [{
				label: 'DAPP',
				value: 1,
			},
			{
				label: 'Arbitrum One',
				value: 2,
			},
			],

			current: 0,
			days: 90,
			dayList: []
		};
	},
	computed: {
		i18n() {
			return this.$t('pledge')
		}
	},
	created() {
		this.$dapp.useAddress(() => {
			this.$nextTick(() => {
				this.getUserInfo();
				this.getBalance()
				this.getList()
				this.getInfo()
				this.getConfig()
			})
		})

	},
	methods: {
		async getConfig() {

			const {
				err,
				res
			} = await config();
			this.dayList = res;


		},
		async getUserInfo() {

			const {
				err,
				res
			} = await accountInfo();
			this.userInfo = res;
			const arc = await this.$dapp.act();
			this.arcBalance = await arc.balanceOf(this.userInfo.address)

		},
		async refund(id) {
			const {
				err,
				res
			} = await remove({
				id: id
			});

			if (err === null) {
				const investShop = await this.$dapp.investShop();
				const investMoreShop = await this.$dapp.investMoreShop();
				if (id > 411) {
					const [error, tx] = await investMoreShop.removeInvest(res);
					if (error === null) {
						this.$toast(this.$t("app.opeSuccess"));
					}
				} else {
					const [error, tx] = await investShop.removeInvest(res);
					if (error === null) {
						this.$toast(this.$t("app.opeSuccess"));
					}
				}

			}

		},
		async nowGet() {

			if (this.amount == "") {
				return;
			}
			if (this.amount <= 0) {
				return;
			}
			if (parseFloat(this.amount) < parseFloat(this.config.amount)) {
				this.$toast(this.$t("pledge.amountErr") + this.config.amount);
				return;
			}

			await this.$dapp.onConnect();
			if (this.selectIndex == 0) {
				const data = {
					address: this.userInfo.address,
					amount: this.amount,
					days: this.days,
					deadline: parseInt(Date.now() / 1000 + 60).toString(),
				};
				const [error, sign] = await this.$dapp.signMessage(data);
				if (error === null) {
					const {
						err,
						res
					} = await add({
						amount: this.amount,
						days: parseInt(this.days),
						sign,
						deadline: Number(data.deadline),
					});
					if (err === null) {
						this.$toast(this.$t("app.opeSuccess"));
						this.amount = "";
						this.getList()
						this.getBalance()
					}
				}
			} else {
				const investShop = await this.$dapp.investMoreShop();
				const act = await this.$dapp.act();
				await act.approve(investShop.address);
				const [error, tx] = await investShop.addInvest(this.amount,this.days);
				if (error === null) {
					this.amount = ''
					this.getUserInfo()
					this.$toast(this.$t("app.opeSuccess"));
				}
			}


		},

		async getBalance() {
			const {
				err,
				res
			} = await balance();
			if (err === null) {
				this.wallet = res;
			}
		},
		async getInfo() {
			const {
				err,
				res
			} = await info();
			if (err === null) {
				this.config = res;
			}
		},
		async getList() {
			const {
				err,
				res
			} = await myList();
			if (err === null) {
				this.list = res;
			}
		},
		changeDay(index, days) {
			this.days = days;
			console.log(this.days)
			if (this.current === index) {
				return
			}
			this.current = index
		}
	}
}
</script>

<style lang="scss" scoped>
.my-container {
	.top-box {
		width: 100%;
		// height: auto;
		position: relative;
		box-sizing: border-box;
		padding-top: 50px;

		.top-border-bg {
			width: 100%;
			height: 300px;
			background: url('../../assets/image/border-bg.png');
			position: absolute;
			left: 0;
			top: 0;
		}

		.side-border {
			width: 100%;
			height: 387px;
			border-left: 2px solid #51B106;
			border-right: 2px solid #51B106;
			position: absolute;
			left: 0;
			bottom: 0;
		}

		.pledge-box {

			width: 643px;
			height: 736px;
			background: url('../../assets/image/info-bg.png');
			position: relative;
			box-sizing: border-box;
			padding-top: 60px;

			.title-box {
				width: 643px;
				height: 50px;
				// border: 1px solid red;
				position: absolute;
				top: -26px;
				left: 0;

				img {
					width: 54px;
					height: 30px;
				}

				font-size: 42px;
			}
		}

		.days {
			align-items: baseline;
			color: #74f800;

			span:nth-child(1) {
				font-size: 74px;
				margin-right: 10px;
			}
		}

		.day-box {
			.day-item {
				width: 181px;
				height: 107px;
				background: url('../../assets/image/pledge-bg-dark.png');
				color: #74f800;

				&.active {
					background: url('../../assets/image/pledge-bg.png');
				}
			}
		}

		.the-balance {
			width: 100%;
		}

		.the-input {
			width: 100%;

			/deep/ .van-cell {
				box-sizing: border-box;
				border-radius: 15px;
				background: #e9e9e9;
			}

			input {
				background: transparent;
				border: none;
				width: 330px;
			}

			.the-icon {
				width: 60px;
				height: 60px;
				margin-right: 10px;
			}
		}

		// 改变输入框占位符字体颜色
		/deep/ input::-webkit-input-placeholder {
			color: #8a8b91 !important;
		}

		.buy-btn {
			width: 565px;
			height: 77px;
			background: url('../../assets/image/btn-bg.png');
			margin: 0 auto;
			margin-top: 60px;
		}

	}

	.bottom-border {
		width: 100%;
		height: 2px;
		background: url('../../assets/image/bottom-border.png');
	}

	.detail-box {
		width: 100%;
		// background: rgba(13, 18, 51, .8);
		border-radius: 15px;
		padding-bottom: 30px;

		.d-title {
			text-align: left;
		}

		.d-item {
			width: 100%;
			height: 178px;
			background: rgba(45, 159, 0, 0.3);
			margin-bottom: 30px;
			box-shadow: 0px 0px 13px 0px rgba(65, 126, 150, 0.25);
			border-radius: 12px;

			&>div {
				width: 100%;
			}

			&:nth-last-child(1) {
				margin-bottom: 0;
			}

			.time {
				width: 100%;
				height: 80px;
				border-radius: 10px;
				font-size: 22px;

				span:nth-child(2) {
					color: #F1FF58;
				}
			}

			.info {
				width: 100%;

				&>div {
					width: 100%;
					display: flex;
					justify-content: space-between;
					align-items: center;

					span {
						display: inline-block;
						width: 50%;
					}
				}

				.label {
					font-size: 22px;
					color: #85B871;
				}

				.value {
					font-size: 25px;
					margin-top: 10px;
				}
			}

			.operate-box {
				width: 200px;
				height: 100%;
			}

			.operate {
				width: 168px;
				height: 65px;
				background: url('../../assets/image/ransom-bg-active.png');
				color: #112502;
			}

			.disabled {
				background: url('../../assets/image/ransom-bg.png');
			}
		}
	}

	.empty-tips {
		font-size: 26px;
		color: #7CA660;
	}
}
</style>